<template>
  <div class="refinedEdition">
    <div class="search_sList">
      <div class="top_opera">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          label-width="80px"
        >
          <el-form-item label="试卷名称">
            <el-input
              v-model="formInline.name"
              placeholder="试卷名称"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="所属课程">
            <el-select
              v-model="formInline.courseId"
              placeholder="请选择"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in courseData"
                :key="index"
                :label="item.courseName"
                :value="item.courseId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="btn_area">
          <el-button
            class="mainBtn"
            @click="onSubmit"
            plain
            v-preventReClick
            >搜索</el-button
          >
          <el-button type="info" @click="resetEvent" plain>重置</el-button>
        </div>
      </div>
    </div>
    <div class="table_sList">
      <div class="opera_btnArea">
        <el-button class="mainBtn" @click="addEvent" plain>添加试卷</el-button>
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        style="width: 100%"
        align="center"
      >
        <el-table-column
          prop="name"
          label="试卷名称"
          align="center"
        >
          <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.name"
              placement="top"
              effect="light"
              popper-class="pubToop"
            >
              <div class="ellipsis">{{ scope.row.name || '/' }}</div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
          prop="courseName"
          label="所属课程"
          align="center"
          :formatter="regCourse"
        >
          <!-- <template slot-scope="scope">
            <el-tooltip
              :content="scope.row.courseName"
              placement="top"
              effect="light"
              popper-class="pubToop"
            >
              <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
            </el-tooltip>
          </template> -->
        </el-table-column>
        <el-table-column
          prop=""
          label="课程分类"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="year"
          label="年份"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="questionCount"
          label="题目数量"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="type"
          label="试卷类型"
          align="center"
          :formatter="regType"
        >
        </el-table-column>
        <el-table-column
          prop="time"
          label="考试时长"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="score"
          label="试卷总分"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="passScore"
          label="合格分数"
          align="center"
        >
        </el-table-column>
        <el-table-column
          fixed="right"
          label="操作"
          width="100"
          align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="deleteEvent(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination
        @changePage="changePage"
        :childMsg="childMsg"
      ></Pagination>
    </div>
    <Keydown @keydownEvent='handleKeydown'></Keydown>
    <dialogDiyNew :isShow="isShowSpecial" :dialogDiyData="dialogSpecialDiyData"
    @dialogEvent="isShowSpecial = false">
      <div slot="dialogContent" class="ref_area">
        <div class="man-title" v-if="selectData.length != 0">已添加试卷：</div>
          <div class="select-area">
            <div class="manage" v-for="(item, index) in selectData" :key="index" @mousemove="showEvent(index, 'over')" @mouseleave="showEvent(index, 'leave')">
              <img class="manClose" v-show="selectData[index].isShow" src="../../../assets/img/man-close.png" alt="" @click="byDeleteEvent(index)">
              <span>{{item.name || '/'}}</span>
            </div>
          </div>
        <div class="search_sList_new">
          <div class="top_opera">
            <el-form
              :inline="true"
              :model="specialForm"
              class="demo-form-inline"
              label-width="80px"
            >
              <el-form-item label="试卷名称">
                <el-input
                  v-model="specialForm.name"
                  placeholder="试卷名称"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="所属课程">
                <el-select
                  v-model="specialForm.courseId"
                  placeholder="请选择"
                  filterable
                  clearable
                >
                  <el-option
                    v-for="(item, index) in courseData"
                    :key="index"
                    :label="item.courseName"
                    :value="item.courseId"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="试卷类型">
                <el-select v-model="specialForm.type" placeholder="选择状态" filterable clearable disabled>
                  <el-option label="历年真题和模拟试题" :value="0"></el-option>
                  <el-option label="模拟试题" :value="1"></el-option>
                  <el-option label="作业" :value="2"></el-option>
                  <el-option label="历年真题" :value="3"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn_search_area">
              <el-button
                class="mainBtn"
                @click="onSubmitEvent"
                plain
                v-preventReClick
                >搜索</el-button
              >
              <el-button type="info" @click="resetOpera" plain>重置</el-button>
            </div>
          </div>
        </div>
        <div class="table_list_new">
          <el-table
            :data="addTableData"
            stripe
            border
            style="width: 100%"
            align="center"
            max-height="400"
          >
            <el-table-column
              prop="name"
              label="试卷名称"
              align="center"
            >
              <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.name"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.name || '/' }}</div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column
              label="所属课程"
              align="center"
              :formatter="regCourse"
            >
              <!-- <template slot-scope="scope">
                <el-tooltip
                  :content="scope.row.courseName"
                  placement="top"
                  effect="light"
                  popper-class="pubToop"
                >
                  <div class="ellipsis">{{ scope.row.courseName || '/' }}</div>
                </el-tooltip>
              </template> -->
            </el-table-column>
            <el-table-column
              prop=""
              label="课程分类"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="year"
              label="年份"
              align="center"
              width="60"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              label="试卷类型"
              align="center"
              :formatter="regType"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="time"
              label="考试时长"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="score"
              label="试卷总分"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              prop="passScore"
              label="合格分数"
              align="center"
              width="80"
            >
            </el-table-column>
            <el-table-column
              fixed="right"
              label="操作"
              width="90"
              align="center">
              <template slot-scope="scope">
                <el-button :disabled="checkAdd(scope.row)" type="text" @click="addSpecial(scope.row)">{{checkAdd(scope.row) ? '已添加' : '添加'}}</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination
            @changePage="changeOperaPage"
            :childMsg="childOperaMsg"
          ></Pagination>
        </div>
        <div class="btn_area">
          <el-button @click="isShowSpecial = false">取消</el-button>
          <el-button type="primary" @click="enterEvent">确认</el-button>
        </div>
      </div>
    </dialogDiyNew>

    <dialogDiy
      :isShow="isShowDel"
      :dialogDiyData="dialogDelDiyData"
      @dialogEvent="isShowDel = false"
    >
      <div slot="dialogContent" class="dia_ref_opera">
        <img class="errIcon" src="../../../assets/img/warn.png" alt="" />
        <p>确定要删除该试卷吗？</p>
        <div class="btn_area">
          <el-button @click="isShowDel = false">关闭</el-button>
          <el-button class="mainBtn" @click="doEvent">确定</el-button>
        </div>
      </div>
    </dialogDiy>
  </div>
</template>

<script>
import merge from 'webpack-merge';
import Pagination from '../../Pagination'
import dialogDiy from '../../commonModule/dialogDiy'
import dialogDiyNew from '../../commonModule/dialogDiyNew'
export default {
  name: 'refinedEdition',
  components: {
    Pagination,
    dialogDiy,
    dialogDiyNew
  },
  data () {
    return {
      formInline: {
        action: 0,
        aidClassId: null,
        courseId: null,
        courseIds: null,
        id: null,
        ids: null,
        isDisable: null,
        name: null,
        page: 1,
        pageSize: 10,
        sourceId: null,
        sourceIds: null,
        sourceType: 0,
        status: null,
        teachingPlanId: null,
        type: null,
        year: null
      },
      tableData: [],
      addTableData: [],
      childMsg: {
        current: 1,
        size: 10,
        total: 1,
      },
      isShowSpecial: false,
      dialogSpecialDiyData: {
        title: '添加试卷',
        sizeStyle: {width: '1000px',height: '810px'}
      },
      specialForm: {
        action: 1,
        aidClassId: null,
        courseId: null,
        courseIds: null,
        id: null,
        ids: null,
        isDisable: null,
        name: null,
        page: 1,
        pageSize: 10,
        sourceId: null,
        sourceIds: null,
        sourceType: 0,
        status: null,
        teachingPlanId: null,
        type: null,
        year: null
      },
      selectData: [],
      childOperaMsg: {
        current: 1,
        size: 10,
        total: 1
      },
      isShowDel: false,
      dialogDelDiyData: {
        title: null,
        sizeStyle: {width: '400px',height: '234px'}
      },
      rowData: null,
      courseData: [],
      idsArr: [],
    }
  },
  created() {
    this.specialForm.aidClassId = this.$route.query.aidClassId;
    this.formInline.aidClassId = this.$route.query.aidClassId;
    // this.formInline.ids = this.$route.query.ids
    this.formInline.type = this.$route.query.type
    this.specialForm.type = Number(this.$route.query.type)
    // this.formInline.year = this.$route.query.year
    this.getYkSpecialList()
    this.getCourseAidList()
  },
  methods: {
    handleKeydown(event) {
      if (!this.isShowDel&&!this.isShowSpecial) {
        this.onSubmit()
      }
    },
    // 搜索
    onSubmit() {
      this.getYkSpecialList();
    },
    // 重置
    resetEvent() {
      this.formInline.courseId = null
      this.formInline.courseIds = null
      this.formInline.id = null
      this.formInline.isDisable = null
      this.formInline.name = null
      this.formInline.page = 1
      this.formInline.pageSize = 10
      this.formInline.sourceId = null
      this.formInline.sourceIds = null
      this.formInline.sourceType = 0
      this.formInline.status = null
      this.formInline.teachingPlanId = null
      this.formInline.year = null
    },
    // 搜索
    onSubmitEvent() {
      this.ykSpecialListEvent();
    },
    // 重置
    resetOpera() {
      this.specialForm.courseId = null
      this.specialForm.courseIds = null
      this.specialForm.id = null
      this.specialForm.ids = null
      this.specialForm.isDisable = null
      this.specialForm.name = null
      this.specialForm.page = 1
      this.specialForm.pageSize = 10
      this.specialForm.sourceId = null
      this.specialForm.sourceIds = null
      this.specialForm.sourceType = 0
      this.specialForm.status = null
      this.specialForm.teachingPlanId = null
      this.specialForm.year = null
    },
    addEvent() {
      this.selectData = []
      this.isShowSpecial = !this.isShowSpecial
      this.dialogSpecialDiyData.sizeStyle.height = '810px'
      this.ykSpecialListEvent()
    },
    regType(row) {
      let edu = String(row.type);
      let val = "";
      switch (edu) {
        case "0":
          val = "历年真题";
          break;
        case "1":
          val = "模拟试题";
      }
      return val;
    },
    regCourse(row) {
      let val;
      this.courseData.forEach((item, index) => {
        if(item.courseId == row.courseId) val = item.courseName
      })
      return val;
    },
    // 获取表格数据
    getYkSpecialList() {
      let obj = this.formInline;
      let aidClassId = this.$route.query.aidClassId;
      this.$request.examPaperList(obj, aidClassId).then(res => {
        if (res.data.code == 0) {
          this.idsArr = res.data.data.ids;
          let { records, current, size, total } = res.data.data.page;
          this.tableData = records
          this.childMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    ykSpecialListEvent() {
      this.$request.examPaperList(this.specialForm).then(res => {
        if (res.data.code == 0) {
          this.idsArr = res.data.data.ids;
          let { records, current, size, total } = res.data.data.page;
          this.addTableData = records
          this.childOperaMsg = {
            current,
            size,
            total
          };
        }
      });
    },
    changePage(val) {
      this.childMsg = val;
      this.formInline.page = val.current;
      this.formInline.pageSize = val.size;
      this.getYkSpecialList();
    },
    changeOperaPage(val) {
      this.childOperaMsg = val;
      this.specialForm.page = val.current;
      this.specialForm.pageSize = val.size;
      this.ykSpecialListEvent();
    },
    // 获取助学课程列表
    getCourseAidList() {
      this.$request.courseAidList({aidClassId: this.$route.query.aidClassId}).then(res => {
        if(res.data.code == 0) {
          this.courseData = res.data.data;
        }
      })
    },
    addSpecial(row) {
      this.selectData.push({
        name: row.name,
        id: row.id,
        courseId: row.courseId
      });
      this.dialogSpecialDiyData.sizeStyle.height = '910px'
    },
    checkAdd(row) {
      let index = this.selectData.findIndex(item => item.id == row.id)
      let isIndex = this.idsArr.findIndex(item => item == row.id)
      if(index > -1 || isIndex > -1)
        return true;
      else
        return false;
    },
    byDeleteEvent(index) {
      this.selectData.splice(index, 1);
    },
    showEvent(index, type) {
      if(type == 'over')
        this.$set(this.selectData[index], 'isShow', true)
      else
        this.$set(this.selectData[index], 'isShow', false)
      this.$forceUpdate()
    },
    deleteEvent(row) {
      this.isShowDel = !this.isShowDel;
      this.rowData = row;
    },
    enterEvent() {
      if(this.selectData.length == 0) {
        this.Warn('请选择试卷');
        return;
      }
      // let ids = []
      let resList = []
      this.selectData.forEach(item => {
        // ids.push(item.id)
        resList.push({
          courseId: item.courseId,
          resId: item.id
        })
      })
      // let idss = this.formInline.ids ? this.formInline.ids + ',' : ''
      // let nIds = idss + ids.join(',')
      // const items = new Set(nIds.split(','));
      // this.formInline.ids = Array.from(items).join(',');
      let dto = {
        aidClassId: this.$route.query.aidClassId,
        // "ids": this.formInline.ids,
        resList,
        type: this.$route.query.type == 3 ? 0 : 1
      }
      this.$request.addExamPaperList(dto).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg)
          this.getYkSpecialList()
          this.isShowSpecial = !this.isShowSpecial
          this.clearspecialForm()
          // this.$router.push({
          //   query:merge(this.$route.query,{'ids': this.formInline.ids})
          // })
        }
      })
    },
    doEvent() {
      let obj = {
        aidClassId: this.$route.query.aidClassId,
        examPaperId: this.rowData.id,
        type: this.rowData.type
      }
      this.$request.deleteExamPaperList(obj).then(res => {
        if(res.data.code == 0) {
          this.Success(res.data.msg);
          // this.reduceIds(this.rowData.id);
          this.isShowDel = !this.isShowDel;
          this.getYkSpecialList();
        }
      })
    },
    reduceIds(id) {
      let idArr = this.formInline.ids.split(',')
      idArr.forEach((item, index) => {
        if(item == id) idArr.splice(index, 1)
      })
      this.formInline.ids = idArr.join(',')
      this.$router.push({
        query:merge(this.$route.query,{'ids': this.formInline.ids})
      })
    },
    clearspecialForm() {
      this.dialogSpecialDiyData.sizeStyle.height = '810px'
      this.selectData = []
      this.resetOpera()
    }
  }
}
</script>

<style lang='less'>
  .refinedEdition {
    .man-title {
      font-size: 16px;
      color: #333;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    .select-area {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 6px;
      .manage {
        position: relative;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #1A7EE2;
        background-color: #a3cbf3;
        cursor: pointer;
        padding: 6px 10px;
        text-align: center;
        margin-left: 6px;
        margin-top: 6px;
        .manClose {
          position: absolute;
          top: -5px;
          right: -5px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .search_sList, .search_sList_new {
      background-color: #fff;
      padding: 28px 40px;
      .top_opera {
        display: flex;
        .el-form {
          flex: 4;
          .el-form-item__content {
            width: 218px;
          }
        }
        .btn_area, .btn_search_area {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .table_sList {
      box-sizing: border-box;
      padding: 30px 40px;
      margin-top: 10px;
      background-color: #fff;
      .opera_btnArea {
        margin-bottom: 20px;
      }
      ::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #fff;
      }
      ::-webkit-scrollbar-thumb {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
          background-color: rgba(0, 0, 0, .1);
          border-radius: 3px;
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
      }
      .el-table__header .has-gutter th.is-leaf:nth-last-child(2) {
          border-right: none;
      }
      .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
      .el-pagination {
        text-align: right;
      }
    }
    .search_sList_new {
      padding: 0 40px;
    }
    .table_list_new {
      padding: 0;
      .el-pagination {
        text-align: right;
      }
    }
    .ref_area {
      padding: 30px;
      box-sizing: border-box;
      .btn_area {
        text-align: right;
      }
    }
    .dia_ref_opera {
      height: 230px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      & > p {
        margin: 20px 0;
      }
    }
  }
</style>